import React from "react";

function Statistics() {
  return (
    <div className="container mt-5 mb-5 bg-body-secondary p-3 rounded">
      <div className="row mb-2">
        <div className="col-md-12 text-center">
          <div className="lc-block">
            {/* <span editable="inline" className="small mt-4 d-block">
              Speak Your Menu
            </span> */}
            <h2 editable="inline" className="display-2 mb-0">
              <b>Why Invest in Training?</b>
            </h2>
            <h4 editable="inline" className="text-secondary">
            Training restaurant staff has traditionally been expensive, time-consuming, and inconsistent due to high turnover rates. Here's the reality:
            </h4>
          </div>
        </div>
      </div>
      <div className="row counters">
        <div className="col-lg-3 col-6 text-center">
          <div className="lc-block">
            <span className="display-4" editable="inline">
              <b>15.5 million</b>
            </span>
            <div editable="rich">people employed in restaurants in the US.</div>
          </div>
        </div>

        <div className="col-lg-3 col-6 text-center">
          <div className="lc-block">
            <span className="display-4" editable="inline">
              <b>$1.1 trillion</b>
            </span>
            <div editable="rich">
              <p>annual revenue of restaurant industry</p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-6 text-center">
          <div className="lc-block">
            <span className="display-4" editable="inline">
              <b>79.6%</b>
            </span>
            <div editable="rich">turnover rate of industry in past 10 yrs.</div>
          </div>
        </div>

        <div className="col-lg-3 col-6 text-center">
          <span className="display-4" editable="inline">
            <b style={{color: 'red'}}>19%</b>
          </span>
          <div editable="rich"> of restaurants DO training</div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
