import React from "react";

import photo1 from "../assets/content_section_1.webp";
import photo2 from "../assets/content_section_2.webp";
import "../styles/contentSection.css";

function ContentSection() {
  return (
    <div className="container-fluid py-5 overflow-hidden">
      <div className="row flex-lg-row-reverse g-0 align-items-center justify-content-around">
        <div className="col-lg-6 ">
          <div className="lc-block row justify-content-center">
            <img
              className="content-image d-md-none img-fluid w-50"
              src={photo1}
              sizes=""
              width=""
              height=""
              alt="Restaruant scene"
              loading="lazy"
            />
            <img
              className="content-image d-none d-md-block img-fluid w-50"
              src={photo1}
              sizes=""
              width=""
              height=""
              alt="Restaruant scene"
              loading="lazy"
            />
          </div>
        </div>
        <div className="col-lg-5">
          <div className="lc-block p-3">
            <div editable="rich">
              <h1>Our Solution</h1>
              <p className="lead">
                Speak Your Menu introduces a progressive, lesson-based education
                app focused exclusively on teaching your restaurant’s menu. By
                using language education principles, repetition, and recall, we
                help increase sales and elevate customer satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-0 align-items-center justify-content-around">
        <div className="col-lg-5">
          <div className="lc-block row justify-content-center">
            <img
              className="content-image d-md-none img-fluid w-50"
              src={photo2}
              sizes=""
              height=""
              alt="Restaruant scene"
              loading="lazy"
            />
            <img
              className="content-image d-none d-md-block img-fluid w-50"
              src={photo2}
              sizes=""
              height=""
              alt="Restaruant scene"
              loading="lazy"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="lc-block p-3">
            <div editable="rich">
              <h1>Affordable and Impactful</h1>
              <p className="lead">
                Designed with cost-efficiency in mind, <strong>Speak Your Menu</strong> addresses
                one of the biggest challenges in the hospitality
                industry—training a constantly evolving staff. Empower your team
                without cutting into your profits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentSection;
