import React from "react";
import { Link } from "react-router-dom";

import logo from '../assets/SYM_logo_with_text_cropped.svg';
import logoBanner from '../assets/SYM_email_banner.svg';
import logoMedium from '../assets/SYM_email_banner_speechBubble.svg'


function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light py-4">
    <div className="container-fluid">
        <Link to="/" className="navbar-brand">
            <img className="img-fluid" src={logoMedium} alt="" width="250px" height="48px"/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#nav_lc" aria-controls="nav_lc" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="nav_lc">
            <ul className="navbar-nav my-3 my-lg-0 ms-lg-3 me-auto">
                <li className="nav-item me-4"><Link to="/" className="nav-link">Home</Link></li>
                <li className="nav-item me-4"><Link to="/about" className="nav-link">About</Link></li>
                <li className="nav-item me-4"><Link to="/contact" className="nav-link">Contact Us</Link></li>
            </ul>
            {/* <div><a className="btn btn-outline-secondary me-2" href="#">Sign In</a><a className="btn btn-primary" href="#">Sign Up</a></div> */}
        </div>
    </div>
</nav>
  );
}

export default Nav;
