import React from "react";

function Features() {
  return (
    <div
      className="bg-light"
      style={{ paddingTop: "100px", paddingBottom: "100px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="lc-block">
              {/* <span editable="inline" className="small mt-4 d-block">
              LOREM IPSUM
            </span> */}
              {/* <h2 editable="inline" className="display-2 mb-0">
              Features
            </h2> */}
              {/* <p editable="inline" className="">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et
              metus id
              <br /> ligula malesuada placerat sit amet quis enim.
            </p> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <div
              className="lc-block text-center rounded shadow text-primary bg-light px-4 py-5"
              style={{ minHeight: "50vh" }}
            >
              <svg
                width="4em"
                height="4em"
                viewBox="0 0 218 178"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.7332 0C40.7832 0 33.4832 7.3 33.4832 16.25C33.4832 25.2 40.7332 32.5 49.7332 32.5C58.7332 32.5 65.9832 25.2 65.9832 16.25C65.9832 7.3 58.7332 0 49.7332 0ZM168.333 0C159.333 0 152.083 7.3 152.083 16.25C152.083 25.2 159.333 32.5 168.333 32.5C177.283 32.5 184.583 25.2 184.583 16.25C184.583 7.3 177.283 0 168.333 0ZM66.7832 90.5L57.7332 84.65C50.1332 79.7 50.9832 69.7 57.2332 64.95C60.3332 62.65 64.4332 61.75 68.8832 63.6L70.0832 48.5L97.1832 30.95C102.133 27.75 98.1332 19.85 92.5332 22.2L65.0332 33.8C60.9332 37.35 55.5832 39.55 49.7332 39.55C43.8832 39.55 38.5332 37.4 34.4332 33.8L6.88316 22.25C1.33316 19.9 -2.71684 27.8 2.23316 31L29.3332 48.55L35.7832 129.7H46.1832V82.85H53.1832V129.7H63.6332L66.7832 90.5ZM149.133 63.6C157.633 60.05 165.333 66.55 165.733 74.3C165.933 78.15 164.333 82.05 160.283 84.65L151.233 90.5L154.333 129.7H164.783V82.85H171.783V129.7H182.183L188.633 48.55L215.733 31C220.683 27.8 216.683 19.9 211.083 22.25L183.533 33.8C179.433 37.35 174.083 39.55 168.233 39.55C162.383 39.55 157.033 37.4 152.933 33.8L125.383 22.25C119.833 19.9 115.783 27.8 120.733 31L147.783 48.55L149.133 63.6ZM124.333 81.6C120.233 85.15 114.883 87.35 109.033 87.35C103.183 87.35 97.8332 85.2 93.7332 81.6L66.1832 70.05C60.6332 67.7 56.5832 75.6 61.5332 78.8L88.6332 96.35L95.0832 177.55H105.533V130.7H112.533V177.55H122.933L129.383 96.35L156.483 78.8C161.433 75.6 157.433 67.7 151.833 70.05L124.333 81.6ZM109.033 47.8C100.083 47.8 92.7832 55.05 92.7832 64.05C92.7832 73.05 100.083 80.3 109.033 80.3C117.983 80.3 125.283 73.05 125.283 64.05C125.283 55.1 117.983 47.8 109.033 47.8Z"
                  fill="#0d6efd"
                />
              </svg>
              <h4 className="mt-3 mb-4" editable="inline">
                Engaging Staff Training
              </h4>
              <p editable="inline">
                Empower your team with lessons designed to be interactive,
                gamified, and easy to follow. Our approach keeps staff engaged
                while boosting their confidence to upsell and connect with
                customers.
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div
              className="lc-block text-center rounded shadow text-light px-4 py-5 bg-primary"
              style={{ minHeight: "50vh" }}
            >
              <svg
                width="4em"
                height="4em"
                viewBox="0 0 124 138"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.98203 58H17.0261C18.1146 58 19 57.1007 19 55.9868V55.005C19 53.8993 18.1146 53 17.0261 53H1.98203C0.893529 53 0 53.8993 0 55.005V55.9868C0 57.0924 0.885419 58 1.98203 58Z"
                  fill="#fff"
                />
                <path
                  d="M1.98203 30H17.0261C18.1146 30 19 29.1007 19 27.995V27.0132C19 25.9076 18.1146 25 17.0261 25H1.98203C0.893529 25 0 25.8993 0 27.0132V27.995C0 29.1007 0.885419 30 1.98203 30Z"
                  fill="#fff"
                />
                <path
                  d="M1.98203 85H17.0261C18.1146 85 19 84.1021 19 82.9901V82.0099C19 80.9061 18.1146 80 17.0261 80L1.98203 80.0082C0.893529 80.0082 0 80.9061 0 82.0181V82.9983C0 84.1021 0.885419 85 1.98203 85Z"
                  fill="#fff"
                />
                <path
                  d="M19 110.987V110.013C19 108.908 18.1146 108 17.0261 108H1.98203C0.893529 108 0 108.899 0 110.013V110.987C0 112.092 0.885419 113 1.98203 113H17.0261C18.1146 113 19 112.101 19 110.987Z"
                  fill="#fff"
                />
                <path
                  d="M67.4959 25C55.7108 25 46.018 34.2454 45 46H90C88.9818 34.245 79.2892 25 67.4959 25Z"
                  fill="#fff"
                />
                <path
                  d="M114.794 0.00173971H20.2059C15.1313 0.00173971 11 4.11536 11 9.16825V20.3267H16.6683C20.4986 20.3267 23.6133 23.4281 23.6133 27.242V28.2056C23.6133 32.0194 20.4986 35.1208 16.6683 35.1208H11V47.8502H16.6683C20.4986 47.8502 23.6133 50.9515 23.6133 54.7654V55.729C23.6133 59.5429 20.4986 62.6443 16.6683 62.6443H11V75.3655H16.6683C20.4986 75.3655 23.6133 78.4669 23.6133 82.2808V83.2444C23.6133 87.0583 20.4986 90.1597 16.6683 90.1597H11V102.881H16.6683C20.4986 102.881 23.6133 105.982 23.6133 109.796V110.752C23.6133 114.566 20.4986 117.667 16.6683 117.667H11V128.833C11 133.886 15.1313 138 20.2059 138H114.794C119.869 138 124 133.886 124 128.833V9.16651C124 4.11362 119.869 0.00173971 114.794 0.00173971ZM65.3973 108.533H37.7645C36.3901 108.533 35.2841 107.423 35.2841 106.063C35.2841 104.694 36.3983 103.593 37.7645 103.593H65.3973C66.7717 103.593 67.8777 104.703 67.8777 106.063C67.8777 107.423 66.7717 108.533 65.3973 108.533ZM74.6681 90.4345H37.7564C36.382 90.4345 35.276 89.3251 35.276 87.9647C35.276 86.5962 36.3901 85.495 37.7564 85.495H74.6681C76.0425 85.495 77.1485 86.6044 77.1485 87.9647C77.1566 89.3251 76.0425 90.4345 74.6681 90.4345ZM90.9897 72.3363H37.7559C36.3816 72.3363 35.2756 71.227 35.2756 69.8666C35.2756 68.4981 36.3897 67.3968 37.7559 67.3968H90.9897C92.364 67.3968 93.47 68.5062 93.47 69.8666C93.4782 71.227 92.364 72.3363 90.9897 72.3363ZM101.407 51.1857H33.5836C32.2093 51.1857 31.1033 50.0763 31.1033 48.716C31.1033 47.3475 32.2174 46.2462 33.5836 46.2462H39.7235C40.7645 33.4116 50.2793 23.1511 62.5764 20.9579C62.5601 20.7798 62.5194 20.6097 62.5194 20.4235C62.5194 17.6946 64.7477 15.4759 67.4883 15.4759C70.2288 15.4759 72.4571 17.6946 72.4571 20.4235C72.4571 20.6097 72.4245 20.7798 72.4002 20.9579C84.7857 23.1605 94.3411 33.5254 95.2675 46.2462H101.391C102.766 46.2462 103.872 47.3556 103.872 48.716C103.896 50.0764 102.782 51.1857 101.407 51.1857Z"
                  fill="#fff"
                />
              </svg>

              <h4 className="mt-3 mb-4" editable="inline">
                Customizable Menu Education
              </h4>
              <p editable="inline">
                Speak Your Menu adapts to your restaurant's unique needs,
                creating tailored lessons that reflect your menu and brand
                identity. Whether it’s food, wine, or special pairings, we
                ensure your team is fluent in your offerings.
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div
              className="lc-block text-center rounded shadow text-primary bg-light px-4 py-5"
              style={{ minHeight: "50vh" }}
            >
              <svg
                width="4em"
                height="4em"
                viewBox="0 0 212 212"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38.9446 183.744C30.0103 185.778 20.5947 187.467 10.6807 188.756V201.678H38.9446V183.744Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M79.2085 171.094C70.4155 174.715 61.0065 177.949 50.9442 180.728V201.678H79.2085V171.094Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M119.362 149.344C110.838 155.279 101.443 160.811 91.0982 165.795V201.678H119.362V149.344Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M159.626 111.346C151.831 121.361 142.48 131.154 131.362 140.273V201.678H159.626V111.346Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M164.098 27.8473L148.756 29.341L144.345 39.0694L159.687 37.5758L164.098 27.8473Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M135.251 91.3339C112.461 120.5 73.4237 147.899 10.6787 157.083V181.355C70.4925 173.368 111.46 150.512 138.718 123.697L139.446 102.634L134.251 104.948L135.251 91.3339Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M166.299 62.8133C176.856 67.6992 183.816 65.7324 183.816 65.7324C183.816 65.7324 190.241 53.2609 196.395 37.9944C205.185 17.4941 191.155 7.99971 191.155 7.99971C191.155 7.99971 174.767 3.70173 165.138 23.821C157.709 38.5078 152.559 51.5591 152.559 51.5591C152.559 51.5591 155.666 58.0889 166.299 62.8133ZM187.442 16.1857C191.717 18.1235 193.082 24.3427 190.489 30.0609C187.895 35.7791 182.319 38.8515 178.044 36.9095C173.769 34.9717 172.404 28.7566 174.997 23.0384C177.591 17.3161 183.167 14.2479 187.442 16.1857Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M143.837 111.242C143.769 111.201 143.711 111.163 143.854 111.225L143.837 111.242C143.916 111.292 144.008 111.346 143.817 111.259L143.837 111.242ZM151.067 54.6769C150.021 55.6914 146.868 59.1861 143.692 66.9622C140.842 73.9391 138.716 82.7379 138.716 82.7379C138.716 82.7379 141.681 78.7464 146.612 77.4503C146.612 77.4503 140.266 87.7025 139.979 95.8389C139.979 95.8389 145.269 88.8412 148.768 88.4644C148.768 88.4644 143.82 99.3501 143.878 111.209L143.854 111.225L143.878 111.238V111.209C152.813 103.441 157.727 92.5761 157.727 92.5761C159.75 95.4538 157.972 104.046 157.972 104.046C163.902 98.4682 167.431 86.9407 167.431 86.9407C169.708 91.5036 168.658 96.3647 168.658 96.3647C168.658 96.3647 173.876 88.9696 177.247 82.2286C181.038 74.6472 181.564 69.96 181.632 68.548C179.905 68.8793 173.538 69.666 164.585 65.5212C155.538 61.5048 151.94 56.1758 151.067 54.6769Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M194.052 41.4329L203.036 53.9579L198.625 63.6863L189.64 51.1613L194.052 41.4329Z"
                  fill="#0d6efd"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M50.0539 46.0065C71.6133 46.0065 89.1165 63.3598 89.1165 84.7255C89.1165 106.095 71.6133 123.444 50.0539 123.444C28.4949 123.444 10.9916 106.095 10.9916 84.7255C10.9916 63.3598 28.4949 46.0065 50.0539 46.0065ZM47.6349 66.2541H42.0202C37.3798 66.2541 33.5394 70.1836 33.5394 75.1275V78.2702C33.5394 83.2141 37.3798 87.1477 42.0202 87.1477H58.0871C60.1433 87.1477 61.7304 88.9944 61.7304 91.1848C61.7304 92.22 61.7304 93.2924 61.7304 94.3275C61.7304 96.5138 60.1433 98.3646 58.0871 98.3646H35.9588V103.205H47.6349V109.453H52.4732V103.205H58.0871C62.7279 103.205 66.5683 99.2714 66.5683 94.3275C66.5683 93.2924 66.5683 92.22 66.5683 91.1848C66.5683 86.2409 62.7279 82.3073 58.0871 82.3073H42.0202C39.9644 82.3073 38.3777 80.4606 38.3777 78.2702V75.1275C38.3777 72.9371 39.9644 71.0904 42.0202 71.0904H64.1494V66.2541H52.4732V60.0018H47.6349V66.2541Z"
                  fill="#0d6efd"
                />
              </svg>

              <h4 className="mt-3 mb-4" editable="inline">
                Boost Sales with Expertise
              </h4>
              <p editable="inline">
                Well-trained staff mean higher sales. By equipping your team
                with deep menu knowledge, we help them create memorable guest
                experiences that lead to repeat visits and glowing reviews.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
