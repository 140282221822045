import React from "react";
import aboutPhoto from "../assets/about_photo.jpeg";
import Footer from "./Footer";
import Nav from "./Nav";

function About() {
  return (
    <>
      <Nav />
      <section style={{ marginTop: "100px" }}>
        <div
          className="d-flex container-fluid"
          lc-helper="background"
          style={{
            height: "80vh",
            backgroundImage: `url(${aboutPhoto})`,
            backgroundPosition: "50% 65%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "-100px",
          }}
        ></div>
        <div
          className="container bg-light shadow py-4 rounded mb-5"
          style={{ marginTop: "-100px" }}
        >
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <div className="lc-block">
                <div editable="rich">
                  <p className="fw-bold display-5">Who We Are</p>
                </div>
              </div>
            </div>
            <div className="lc-block col-5">
              <hr />
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-10 col-xxl-8">
              <div className="lc-block mb-5">
                <div editable="rich">
                  <p className="rfs-9 fs-4">
                    At <strong>Speak Your Menu</strong>, we’re passionate about hospitality and
                    dedicated to transforming restaurant education. With years
                    of industry experience, we've trained thousands of
                    restaurant professionals and uncovered a powerful
                    insight—menu knowledge is like learning a new language. By
                    applying proven language-learning techniques to staff
                    training, we help restaurants enhance service, boost sales,
                    and improve employee retention. Our goal is simple:
                    empower teams with the confidence and knowledge to deliver
                    exceptional dining experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <div className="lc-block">
                <div editable="rich">
                  <p className="fw-bold display-5">Our Philosophy</p>
                </div>
              </div>
            </div>
            <div className="lc-block col-5">
              <hr />
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-10 col-xxl-8">
              <div className="lc-block mb-5">
                <div editable="rich">
                  <p className="rfs-9 fs-4">
                    Your menu is more than a list of offerings—it’s the story of
                    your restaurant. When your staff can speak this story
                    fluently, they provide a dining experience that keeps
                    customers coming back.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <div className="lc-block">
                <div editable="rich">
                  <p className="fw-bold display-5">Our Mission</p>
                </div>
              </div>
            </div>
            <div className="lc-block col-5">
              <hr />
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-10 col-xxl-8">
              <div className="lc-block mb-5">
                <div editable="rich">
                  <p className="rfs-9 fs-4">
                    To revolutionize restaurant training by delivering an
                    accessible, affordable, and effective platform that
                    increases profits, boosts customer satisfaction, and
                    empowers teams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
